<div class="bgImg1">
    <div class="container" style="padding-top: 44px;">
        <div class="text-center">
            <p class="fontSize28 whiteColor">Blogs</p>
            <p class="whiteColor">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
        </div>
        
    </div>
</div>

<div class="container">
    <div class="row">
        <div *ngFor="let blog of blogsData" class="col-lg-3"  style="padding-top: 30px; padding-bottom: 30px;">
            <div  style="box-shadow: 0px 3px 6px #00000029; border-radius: 10px;" routerLink="./viewblog1/{{blog.blogId}}">
                <img style="width: 100%; height: 176px;" src="https://api.manteio.in/{{blog.thumbnailPath}}" alt="">
                <div style="padding: 10px;">
                    <p style="font-size: 14px; color: #012D65; margin-bottom: 7px;">{{blog.blogCategoryId}}</p>
                    <p class="overflowHidden" style="color: #0C213A; font-size: 16px; font-weight: bold; margin-bottom: 11px; height: 47px;">{{blog.title}}</p>
                    <p class="overflowHidden height65" style="color: #333333; font-size: 14px; margin-bottom: 7px; "><quill-view-html [content]="blog?.blogContent"></quill-view-html></p>
                    <p style="color: #9BACBD; font-size: 11px; margin-bottom: 10px;">{{blog.createdOn | date:'longDate'}}</p>
                </div>
               
            </div>
           
        </div>
    </div>
</div>