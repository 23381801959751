import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from 'src/app/core/services/service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-theme-two',
  templateUrl: './view-theme-two.component.html',
  styleUrls: ['./view-theme-two.component.scss']
})
export class ViewThemeTwoComponent implements OnInit {
  currencyBind;
  currency;
  yy=[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  mm=[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12]
  VALID = "VALID"
  public isSubmitClicked: boolean = false;
  disappear=false;
  url='';
  fileList:any[] = [];
  openAndClose = false;
  p:Number = 1;
  count:Number = 6;
  totalCount:Number;
  Jobs:any;
  applyJobForm!:FormGroup;
  clientkey = localStorage.getItem('ClientKey');
  jobId ;
  jobs
  constructor(private actRoute:ActivatedRoute, private formBuilder: FormBuilder, private spinner:NgxSpinnerService, private jobService: ServiceService) { }

  ngOnInit(): void {
    this.spinner.show();
    const id = this.actRoute.snapshot.paramMap.get('id');
    console.log(id)
    this.jobService.getJobById(id).subscribe((res:any)=>{
      this.spinner.hide();
      console.log(res.data)
     this.jobs = res.data
    })
    this.applyJobForm = this.formBuilder.group({
      fname:['', [Validators.required, Validators.pattern('[a-zA-Z]*')]],
      lname:['', [Validators.pattern('[a-zA-Z]*')]],
      email:['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      phoneNumber:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      resume:[''],
      currency:[''],
      experienceYear:[''],
      experienceMonth:[''],
      relevantYear:[''],
      relevantMonth:[''],
      currentSalary:['', [Validators.pattern('[0-9]*')]],
      expectedSalary:['',  [Validators.pattern('[0-9]*')]]
    })
    this.jobService.getCurrencyCodes().subscribe((res:any)=>{
      this.currency = res.data
      console.log(this.currency)
    })

  }
  changeCurrency(value){
    console.log(value)
    this.currencyBind= value

  }

  public get applyJobFormControl() { return this.applyJobForm.controls; }

  TrueorFalse(){
    this.openAndClose = !this.openAndClose
  }

   //
   onSelectFilebase(event){
    let data = [...event.target.files];
    this.fileList = data
    const file= event.target.files[0];
    console.log(data)
   this.applyJobForm.get('resume').setValue(file);

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    
    reader.onload = (event) => {
      console.log(event)
      this.disappear=true
      this.url = '../../assets/icons/Yellow Doc icon.png'
    }
  }
  removeDocument(){
    console.log('remove')
    this.disappear=false
  }
//apply button click id passing
modal(jobId){
  console.log(jobId)
  this.jobId = jobId
 }

 applyJob(applyJobForm: {fname:any, lname:any, email:any, phoneNumber:any, resume:any, currency:any, experienceYear:any, experienceMonth:any, relevantYear:any, relevantMonth:any, currentSalary:any, expectedSalary:any}){
     
  this.isSubmitClicked = true;
  console.log(this.applyJobForm.status)
  const xyz = this.applyJobForm.status
  
  if(this.VALID == xyz){
  const formData = new FormData();
  formData.append('FirstName', applyJobForm.fname);
  formData.append('LastName', applyJobForm.lname);
  formData.append('Email', applyJobForm.email);
  formData.append('ContactNumber', applyJobForm.phoneNumber);
  formData.append('CurrencyId', applyJobForm.currency);
  formData.append('TotalWorkExperienceyear', applyJobForm.experienceYear);
  formData.append('TotalWorkExperiencemonth', applyJobForm.experienceMonth);
  formData.append('RelaventExperienceyear', applyJobForm.relevantYear);
  formData.append('RelaventExperiencemonth', applyJobForm.relevantMonth);
  formData.append('CurrentSalary', applyJobForm.currentSalary);
  formData.append('ExpectedSalary', applyJobForm.expectedSalary);
  formData.append('resume', this.applyJobForm.get('resume').value);
  formData.append('ClientKey', 'e9151a21-3325-46be-bc48-bd1340aa3340');
  formData.append('JobId', this.jobId)
  console.log(formData)
  if(formData){
    this.jobService.ApplyJobThroughWebsite(formData).subscribe((res:any) => {
      if(res.statusCode ===1 ){
        console.log('success');
         
        Swal.fire( {
          position: 'center',
          icon: 'success',
          title: 'Successfully Submitted',
          showConfirmButton: false,
          timer: 2500
        })
        window.setTimeout(function(){ 
          location.reload();
      } ,2500);
      }
    })
  }
}
}


}
