<div class="bgImg1">
    <div class="container" style="padding-top: 44px;">
        <div class="text-center">
            <p class="fontSize28 whiteColor">Blogs</p>
            <p class="whiteColor">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
        </div>
        
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-8" style="padding-top: 30px;">
            <div style=" box-shadow: 0px 3px 6px #00000029; padding: 15px; border-radius: 10px;" >
                <p style="font-size: 14px; color: #012D65; margin-bottom: 7px;">{{viewBlog.blogCategoryId}}</p>
                <p style="color: #0C213A; font-size: 21px; font-weight: bold;margin-bottom: 11px;">{{viewBlog.title}}</p>
                <p style="color:#333333; font-size: 12px; margin-bottom: 7px;">{{viewBlog.createdOn}}</p>
                <img class="" style="min-width: 100%; height: 347px; margin-bottom: 25px;" src="https://api.manteio.in/{{viewBlog.fullImagePath}}" alt="">
                <p><quill-view-html [content]="viewBlog?.blogContent"></quill-view-html></p> 
            </div>
           
            
        </div>
        <div class="col-lg-4" style="padding-top: 30px;">
            <div style="box-shadow: 0px 3px 6px #00000029; padding: 20px;  border-radius: 10px;">
                <p class="color333333">Other Blogs</p>
                <div  *ngFor="let blog of blogsData">
                    <hr class="color333333">
                    <div class="row">
                        <div class="col-lg-4">
                          <a href=""><img style="max-width: 100%;" src="https://api.manteio.in/{{blog.thumbnailPath}}" alt=""></a>  
                        </div>
                        <div class="col-lg-8">
                            <a  href="blog1/{{clientkey}}/viewblog1/{{blog.blogId}}" style="text-decoration: none;"><p style="margin-bottom: 5px; letter-spacing: 0.35px; color: #333333; font-weight: bold;">{{blog.title}}</p></a>
                            <p style="font-size: 10px; color: #333333;">{{blog.createdOn}}</p>
                            <!--<p style="color: #585858;">{{blog.date}}</p>-->
                        </div> 
                    </div>
                </div>
                
            </div>
        </div>
    </div><br>
</div>