<ngx-spinner></ngx-spinner>
<div class="section-header-center" style="padding-top: 40px;">
    <h2 class="text-center" style="color:#F09F0F">Apply Now</h2>
</div>
<div class="container" style="padding-top: 10px;">
    
    
    <p class="text-center" style="color: #333333; font-family: sofia Pro, Regular;"> We’re always eager to meet fresh and new talent, so check out our open positions below.</p>
    <!-- <div class="row" style="">
        <div class="col-md-7">
            <input autocomplete="off" [(ngModel)]="searchText" class="form-control" style=" height: 53px; box-shadow: 0px 3px 6px #00000029; border-radius: 8px;" type="text" placeholder="Search for job title">
        </div>
        <div class="col-md-3">
            <input class="form-control displayNone" style=" height: 53px;box-shadow: 0px 3px 6px #00000029; border-radius: 8px;" type="text" placeholder="Enter location">
        </div>
        
        <div class="col-md-2">
            <input type="button" style=" height: 53px;background: linear-gradient(#D58900, #F09F0F) ;border-radius: 8px; box-shadow: 0px 3px 6px #00000029; color:white" class="form-control displayNone" value="Search">
        </div>
    </div> -->
</div>
<br>
<!--accordian-->

<!------>
<div  class="container" style="padding-bottom: 50px;">
    <p>Showing {{p}} - {{count}} of {{totalCount}} results</p>
    <div class="accordion accordion-flush" id="accordionFlushExample" style="padding-bottom: 20px;">

        <div *ngFor="let job of Jobs | filter:searchText | paginate: {itemsPerPage:count, currentPage:p}" class="accordion-item" 
        style="box-shadow: 0px 3px 6px #00000029;
        margin-bottom: 10px;padding-bottom: 10px;
        border-radius: 8px;">

            <h2 style=" " class="accordion-header" [id]="'flush-headingOne'+job.jobId">

           <!--(click)="TrueorFalse()"-->     
           <button    style="border-radius: 8px; background-color: white; padding-bottom: 0px;"  class="accordion-button collapsed arrow" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#xyz'+job.jobId" aria-expanded="false" [attr.aria-controls]="'xyz'+job.jobId">
                   <p style="color: #000000;font-size: 16px;">{{job.jobTitle}}</p><hr style="color: #D40000;">
                   <p class="openAndClose1 marginRight0 width100 textRight"><!--*ngIf="!openAndClose"-->
                    <a href="" style="padding-right: 10px; text-decoration: none;">View Details</a>
                    <button  class="btn btn" data-toggle="modal" data-target="#exampleModal" (click)="modal(job.jobId)"
                    style="background: linear-gradient(#D58900, #F09F0F) ;border-radius: 8px;width: 140px; 
                    color: white;">Apply Now</button>
                </p> 
                </button>
              
                <p  class="openAndClose2" style="padding-left: 20px; font-size: 14px;"><!--*ngIf="!openAndClose"-->
                    <img style="width: 18px; height: 18px;" src="../../assets/icons/Yellow Employment Type icon.png" alt=""> 
                    <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.jobType}}</span>
                    <img style="width: 18px; height: 18px;" src="../../assets/icons/Yellow Experience icon.png" alt=""> 
                    <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.experience}}</span>
                    <img style="width: 18px; height: 18px;" src="../../assets/icons/Yellow Salary icon.png" alt=""> 
                    <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.currencySymbol}}{{job.salary}}</span>
                    <img style="width: 18px; height: 18px;" src="../../assets/icons/Yellow Location icon.png" alt=""> 
                    <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.location}}</span>
                </p>
            </h2>

            <div style=" " [id]="'xyz'+job.jobId" class="accordion-collapse collapse" [attr.aria-labelledby]="'flush-headingOne'+job.jobId"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <p class="jobTypeCSS">Job Type: <span class="jobTypeCSSSpan">{{job.jobType}}</span></p>
                    <p class="jobTypeCSS">Experience: <span class="jobTypeCSSSpan">{{job.experience}}</span></p>
                    <p class="jobTypeCSS">Salary: <span class="jobTypeCSSSpan">{{job.currencySymbol}}{{job.salary}}</span></p>
                    <p class="jobTypeCSS">Location: <span class="jobTypeCSSSpan">{{job.location}}</span></p>
                    <p class="jobDescCss marginBottom0">Job Description</p>
                    <p class="jobDescCss2"><quill-view-html [content]="job?.jobDescription"></quill-view-html></p>
                    <p class="jobDescCss marginBottom0">Role & Responsibilities</p>
                    <p class="jobDescCss2"><quill-view-html [content]="job?.rolesAndResponsibilities"></quill-view-html></p>
                    <p class="jobDescCss marginBottom0">Required Skills</p>
                    <p class="jobDescCss2"><quill-view-html [content]="job?.requiredSkills"></quill-view-html></p>
                    <p class="jobDescCss marginBottom0">Key Skills</p>
                    <p class="jobDescCss2"><quill-view-html [content]="job?.keySkills"></quill-view-html></p>
                    <p class="textRight marginRight0 width100">
                        <a href="theme1/{{clientData}}" style="padding-right: 10px; text-decoration: none; color: #D40000;">Hide Details</a>
                        <button class="btn btn" data-toggle="modal" data-target="#exampleModal" (click)="modal(job.jobId)"
                        style="background: linear-gradient(#D58900, #F09F0F) ;border-radius: 8px;width: 140px; 
                        color: white;">Apply Now</button>
                    </p> 
                </div>
            </div> 

        </div>
        
        <div class="row">
            <div class="col-md-6 text-start">
                <p>Results per page: <select name="" id=""><option value="{{count}}">{{count}}</option></select></p>
            </div>
            <div class="col-md-6 text-end">
                <pagination-controls (pageChange)="p=$event"></pagination-controls>
            </div>
            
        </div>
    </div> 
</div>
<!--<quill-view-html [content]="fullData?.jobDescription"></quill-view-html>-->
<!--<quill-view-html [content]="job?.rolesAndResponsibilities"></quill-view-html>-->
<!--<quill-view-html [content]="job?.jobDescription"></quill-view-html>-->
<!--<quill-view-html [content]="job?.requiredSkills"></quill-view-html>-->
<!--<quill-view-html [content]="job?.keySkills"></quill-view-html>-->

 
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div style=" height: auto;" class="modal-content width646">
        <div class="modal-header">
          <h5 style="margin-left: 37%; color: #F09F0F;" class="modal-title" id="exampleModalLabel">Application Form</h5>
          <button type="button" class="close btn btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="applyJobForm" (ngSubmit)="applyJob(applyJobForm.value)">
        <div class="modal-body">
          <div class="row">
              <div class="col-md-6">
                  <p style="margin-bottom: 7px; font-size: 12px;">First Name<span style="color: red;">*</span></p>
                  <input formControlName="fname" name="fname" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control">
                  <div *ngIf="isSubmitClicked && applyJobFormControl.fname.errors" class=" alert-danger">
                    <div *ngIf="applyJobFormControl.fname.errors.required" style="color: red;">
                        *First Name is required.
                    </div>
                    <div *ngIf="applyJobFormControl.fname.errors.pattern"  style="color: red;">
                        <p style="font-size: 12px;"><sup>*</sup> Must be only alphabetical order.</p>  
                      </div>
                </div>
                <p style="margin-bottom: 7px; font-size: 12px;">Email Address</p>
                <input formControlName="email" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control"> 
                <div *ngIf="isSubmitClicked && applyJobFormControl.email.errors" class=" alert-danger">
                    
                    <div *ngIf="applyJobFormControl.email.errors.pattern"  style="color: red;">
                        <p style="font-size: 12px;"><sup>*</sup> Please enter valid email address</p>  
                      </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <p style="margin-bottom: 7px; font-size: 12px;">Total Experience</p>
                        <div class="row">
                            <div class="col-md-6">
                                <select formControlName="experienceYear" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                                    <option value="">YY</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <select formControlName="experienceMonth" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control"  name="" id="">
                                    <option value="">MM</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <p style="margin-bottom: 7px;  font-size: 12px;">Current CTC</p>
                  <div class="row">
                    <!-- <div class="col-md-4">
                      <select (change)="changeCurrency($event.target.values)" formControlName="currency" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                        <option value="">Curency</option>
                        <option *ngFor="let symbol of currency" value="{{symbol.id}}" values="{{symbol.symbol}}">{{symbol.symbol}}</option>
                    </select>
                    </div> -->
                    <div class="col-md-7">
                      <input formControlName="currentSalary" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control">
                      <div *ngIf="isSubmitClicked && applyJobFormControl.currentSalary.errors" class=" alert-danger">
                    
                        <div *ngIf="applyJobFormControl.currentSalary.errors.pattern"  style="color: red;">
                            <p style="font-size: 12px;"><sup>*</sup> Please enter only numbers</p>  
                          </div>
                    </div>
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                  <p style="margin-bottom: 7px;  font-size: 12px;">Last Name</p>
                  <input formControlName="lname" style="margin-bottom: 17px; border-radius: 8px;" type="text" class="form-control">
                  <div *ngIf="isSubmitClicked && applyJobFormControl.lname.errors" class=" alert-danger">
                    
                    <div *ngIf="applyJobFormControl.lname.errors.pattern"  style="color: red;">
                        <p style="font-size: 12px;"><sup>*</sup> Must be only alphabetical order.</p>  
                      </div>
                </div>
                  <p style="margin-bottom: 7px; font-size: 12px;">Contact Number<span style="color: red;">*</span></p>
                  <div class="row">
                      <!-- <div class="col-md-3">
                        <select style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id=""><option value="">+1</option></select>
                      </div> -->
                      <div class="col-md-9">
                        <input style="margin-bottom: 17px; border-radius: 8px;" formControlName="phoneNumber"  type="text" class="form-control">
                        <div *ngIf="isSubmitClicked && applyJobFormControl.phoneNumber.errors" class=" alert-danger">
                            <div *ngIf="applyJobFormControl.phoneNumber.errors.required">
                                *Contact Number is required.
                            </div>
                            <div *ngIf="applyJobFormControl.phoneNumber.errors.pattern"  style="color: red;">
                                <p style="font-size: 12px;"><sup>*</sup> Must be 10 digits number.</p>  
                              </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                    <p style="margin-bottom: 7px; font-size: 12px;">Relevant Experience </p>
                    <div class="row">
                        <div class="col-md-6">
                            <select formControlName="relevantYear" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                                <option value="">YY</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <select formControlName="relevantMonth" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                                <option value="">MM</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                        </div>
                    </div>
                </div>
                <p style="margin-bottom: 7px; font-size: 12px;">Expected CTC</p>
                <div class="row">
                    <!-- <div class="col-md-6">
                        <select style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                            <option value="">currency</option>
                            <option *ngFor="let symbol of currency" value="">{{symbol.symbol}}</option>
                        </select>
                    </div> -->
                    <div class="col-md-6">
                        <input formControlName="expectedSalary" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control">
                        <div *ngIf="isSubmitClicked && applyJobFormControl.expectedSalary.errors" class=" alert-danger">
                    
                            <div *ngIf="applyJobFormControl.expectedSalary.errors.pattern"  style="color: red;">
                                <p style="font-size: 12px;"><sup>*</sup> Please enter only numbers</p>  
                              </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
          <!--upload-->
        <div class="row">
            <div class="pad">
                <p style=" font-size: 12px;">Upload Resume</p>
                <div *ngIf="!disappear" style="border: 3px dashed #B2B2B2; padding-top: 30px; padding-bottom: 30px; border-radius: 8px;">
                    <label for="fileInput" style="padding-left: 47%;">
                        <img  [src]="url ? url : '../../assets/icons/Yellow Doc icon.png'" alt="">
                        
                    </label>
                    <input type="file" name="resume" id="fileInput"
                    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     (change)="onSelectFilebase($event)">
                    <!--<img src="../../../../assets/admin/icons/document icon.png" style="padding-left: 47%;" alt="">-->
                    <h6 style="color: black; text-align: center; font-size: 12px;">Click here to upload file</h6>
                    <p style="text-align: center; font-size: 10px;">Format: .pdf, .doc, .docx within ( 2Mb )</p>
                </div>
                <!----->
                <div *ngIf="disappear" style="border: 3px dashed #B2B2B2; padding-top: 30px; padding-bottom: 30px; border-radius: 8px;">
                    <div class="row " 
                    style="width: 40%; margin-left: 30%; background: #efeded; border-radius: 6px; padding-top: 5px;padding-bottom: 5px;">
                    <div class="col-md-4">
                        <img src="../../assets/icons/PDF.png" alt="">
                    </div>
                    <div class="col-md-4" *ngFor="let file of fileList; let i = index">
                        <p>{{file.name}}</p>
                    </div>
                    <div class="col-md-4">
                        <a class="btn btn" (click)="removeDocument()">X</a>
                    </div>
                </div>
                </div>
                <!----->
            </div>
        </div>
        </div>
    
        <div class="text-center" style="padding-bottom: 20px;">
          <!--<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
          <button type="submit" style="background: linear-gradient(#D58900, #F09F0F) ; color: white;"  class="btn btn">Submit</button>
        </div>
    </form>
      </div>
    </div>
  </div>
<!--

    <div class="row " *ngIf="disappear"
                            style="background: #efeded; border-radius: 6px; padding-top: 5px;padding-bottom: 5px;">
                            <div class="col-md-4">
                                <img src="../../assets/icons/PDF.png" alt="">
                            </div>
                            <div class="col-md-4" *ngFor="let file of fileList; let i = index">
                                <p>{{file.name}}</p>
                            </div>
                            <div class="col-md-4">
                                <a class="btn btn" (click)="removeDocument()">X</a>
                            </div>
                        </div>
-->
 