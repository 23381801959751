import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogOneComponent } from './blog-one/blog-one.component';
import { ViewBlogOneComponent } from './blog-one/view-blog-one/view-blog-one.component';
import { ThemeOneComponent } from './theme-one/theme-one.component';
import { ThemeThreeComponent } from './theme-three/theme-three.component';
import { ViewThemeThreeComponent } from './theme-three/view-theme-three/view-theme-three.component';
import { ThemeTwoComponent } from './theme-two/theme-two.component';
import { ViewThemeTwoComponent } from './theme-two/view-theme-two/view-theme-two.component';

const routes: Routes = [
  {path:'', component:ThemeOneComponent},
  //{path:'theme1', component:ThemeOneComponent},
  {path:'theme1/:id', component:ThemeOneComponent},
  {path:'theme2', component:ThemeTwoComponent},
  {path:'viewtheme2/:id', component:ViewThemeTwoComponent},
  {path:'theme3', component:ThemeThreeComponent},
  {path:'viewtheme3/:id', component:ViewThemeThreeComponent},
  {path:'blog1/:id', component:BlogOneComponent},
  {path:'blog1/:id/viewblog1/:id', component:ViewBlogOneComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
