import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../core/services/service.service';

@Component({
  selector: 'app-theme-three',
  templateUrl: './theme-three.component.html',
  styleUrls: ['./theme-three.component.scss']
})
export class ThemeThreeComponent implements OnInit {
  p:Number = 1;
  count:Number = 6;
  totalCount:Number;
  Jobs: any;

  constructor(private spinner:NgxSpinnerService, private getAllJobs:ServiceService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getAllJobs.displayAllJobs().subscribe((res:any)=>{
      this.spinner.hide();
      this.totalCount = res.data.length
      this.Jobs = res.data
      console.log(res.data)
    })
  }

}
