<div style="background: url('./../../assets/Theme\ 3\ –\ 1.png');">
    <div class="container">
    <p class="text-center" style="color: #4C53B6;font-size: 28px;font-family: 'sofia Pro', Bold;padding-top: 44px;font-weight: bold;">Job Opportunities</p>
    
    <div class="row" style="padding-top: 20px;">
        <div class="col-md-7">
            <input class="form-control" style="height: 53px;box-shadow: 0px 3px 6px #00000029; border-radius: 8px;" type="text" placeholder="Search for job title">
        </div>
        <div class="col-md-3">
            <input class="form-control" style="height: 53px;box-shadow: 0px 3px 6px #00000029; border-radius: 8px;" type="text" placeholder="Enter location">
        </div>
        
        <div class="col-md-2">
            <input type="button" style="height: 53px;background: linear-gradient(#4C53B6, #8185F9) ;border-radius: 8px; box-shadow: 0px 3px 6px #00000029; color:white" class="form-control" value="Search">
        </div>
    </div>
</div>

<!----> <br>
<div class="container">
    <p>Showing {{p}} - {{count}} of {{totalCount}} results</p>
</div>
<div class="container" *ngFor="let job of Jobs | paginate: {itemsPerPage:count, currentPage:p}"><br>
    <button routerLink="../viewtheme3/{{job.jobId}}"  class="btn btn" style="box-shadow: 0px 3px 6px #00000029; padding: 20px; background-color: white; width: 100%;border-radius: 8px;">
        <p style="text-align: left;">{{job.jobTitle}}</p>
        <p style="text-align: left;"><!--*ngIf="!openAndClose"-->
            <img style="width: 18px; height: 18px;" src="../../assets/icons/Purple Employment Type icon.png" alt=""> 
            <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.jobType}}</span>
            <img style="width: 18px; height: 18px;" src="../../assets/icons/Purple Experience icon.png" alt=""> 
            <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.experience}}</span>
            <img style="width: 18px; height: 18px;" src="../../assets/icons/Purple Salary icon.png" alt=""> 
            <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.currencySymbol}}{{job.salary}}</span>
            <img style="width: 18px; height: 18px;" src="../../assets/icons/Purple Location icon.png" alt=""> 
            <span style="padding-right: 44px;color: #333333;font-size: 14px;"> {{job.location}}</span>
        </p>
        <p style="text-align: left;"><quill-view-html  [content]="job?.jobDescription"></quill-view-html></p>
    </button>
    
</div>
<div class="container"><br>
    <div class="row">
        <div class="col-md-6 text-start">
            <p>Results per page: <select name="" id=""><option value="{{count}}">{{count}}</option></select></p>
        </div>
        <div class="col-md-6 text-end">
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
        </div>
        
    </div>
</div>
</div>
