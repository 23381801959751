import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) { }
  getJobById(jobId) {
    let url = `${environment.apiUrl}admin/GetJobDetailsByBasedOnJobID?JobId=` + jobId;
    return this.http.get(url);
  }
  displayAllJobs() {
    console.log(this.document.location.href.split('/')[4]);
    const clientData = this.document.location.href.split('/')[4];
    const clientJobsBlogs = this.document.location.href.split('/')[3];
    console.log(clientJobsBlogs)
    if (clientData == 'e9151a21-3325-46be-bc48-bd1340aa3340') {
      if (clientJobsBlogs == 'theme1') {
        let url = `${environment.apiUrl}admin/SearchJobsByClientKey`;
        return this.http.get(url, {
          headers: new HttpHeaders({
            'ClientKey': 'e9151a21-3325-46be-bc48-bd1340aa3340'
          })
        });
      }
      else if (clientJobsBlogs == 'blog1') {
        let url = `${environment.apiUrl}admin/GetAllBlogsBasedOnClientKey?Clientkey=e9151a21-3325-46be-bc48-bd1340aa3340`;
        return this.http.get(url)
      }
    } else if (clientData == '210a2c85178b46088876ee6235f79d0a') {
      if (clientJobsBlogs == 'theme1') {
        let url = `${environment.apiUrl}admin/SearchJobsByClientKey`;
        return this.http.get(url, {
          headers: new HttpHeaders({
            'ClientKey': '210a2c85178b46088876ee6235f79d0a'
          })
        });
      }
      else if (clientJobsBlogs == 'blog1') {
        let url = `${environment.apiUrl}admin/GetAllBlogsBasedOnClientKey?Clientkey=210a2c85178b46088876ee6235f79d0a`;
        return this.http.get(url)
      }
    }
  }

  viewBlogById(id){
    const clientData = this.document.location.href.split('/')[4];
    const clientJobsBlogs = this.document.location.href.split('/')[3];
      let url = `${environment.apiUrl}admin/GetAllBlogDetailsBasedOnBlogID?blogId=` + id;
    return this.http.get(url);
 

  }

  ApplyJobThroughWebsite(data) {

    let url = `${environment.apiUrl}admin/ApplyJobThroughWebsite`;
    return this.http.post<any>(url, data)

  }

  getCurrencyCodes() {
    let url = `${environment.apiUrl}Admin/GetAllCurrencyCodes`;
    return this.http.get(url);
  }

}
//, {params: {'ClientKey': "e9151a21-3325-46be-bc48-bd1340aa3340"}}