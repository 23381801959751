import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../core/services/service.service';

@Component({
  selector: 'app-blog-one',
  templateUrl: './blog-one.component.html',
  styleUrls: ['./blog-one.component.scss']
})
export class BlogOneComponent implements OnInit {
  blogsData = []
  constructor(private blogs:ServiceService) { }

  ngOnInit(): void {
    this.blogs.displayAllJobs().subscribe((res:any)=>{
      this.blogsData = res.data
      console.log(res.data)
    })
  }

}
