import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/core/services/service.service';

@Component({
  selector: 'app-view-blog-one',
  templateUrl: './view-blog-one.component.html',
  styleUrls: ['./view-blog-one.component.scss']
})
export class ViewBlogOneComponent implements OnInit {
   clientkey = this.document.location.href.split('/')[4];
  blogsData=[]
  viewBlog
  blogNumber
  constructor(private blogs:ServiceService, private actRoute:ActivatedRoute, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    console.log(this.clientkey)
    const id = this.actRoute.snapshot.paramMap.get('id');
console.log(id)
   this.blogNumber=id
   this.blogs.viewBlogById(id).subscribe((res:any)=>{
     this.viewBlog = res.data.objBlogData
     this.blogsData = res.data.otherblogs
     console.log(res.data.otherblogs)
   })
} 
}
