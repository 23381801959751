import {NgxPaginationModule} from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeOneComponent } from './theme-one/theme-one.component';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill';
import { ThemeTwoComponent } from './theme-two/theme-two.component';
import { ViewThemeTwoComponent } from './theme-two/view-theme-two/view-theme-two.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ThemeThreeComponent } from './theme-three/theme-three.component';
import { ViewThemeThreeComponent } from './theme-three/view-theme-three/view-theme-three.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BlogOneComponent } from './blog-one/blog-one.component';
import { ViewBlogOneComponent } from './blog-one/view-blog-one/view-blog-one.component';
@NgModule({
  declarations: [
    AppComponent,
    ThemeOneComponent,
    ThemeTwoComponent,
    ViewThemeTwoComponent,
    ThemeThreeComponent,
    ViewThemeThreeComponent,
    BlogOneComponent,
    ViewBlogOneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    QuillModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
