<div class="bgImg" style="height: 245px;">
    <div class="container">
        <p class="text-center" style="color: white;font-size: 28px;font-family: 'sofia Pro', Bold;padding-top: 44px;">Our Current Openings</p>
        <p class="text-center" style="color: white;font-size: 14px;font-family: 'sofia Pro', Regular;padding-top: 18pxpx;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
        <div class="row" style="padding-top: 20px;">
            <div class="col-md-7">
                <input class="form-control" style=" height: 53px; box-shadow: 0px 3px 6px #00000029; border-radius: 8px;" type="text" placeholder="Search for job title">
            </div>
            <div class="col-md-3">
                <input class="form-control" style="height: 53px; box-shadow: 0px 3px 6px #00000029; border-radius: 8px;" type="text" placeholder="Enter location">
            </div>
            
            <div class="col-md-2">
                <input type="button" style="height: 53px; background: linear-gradient(#092047, #354E79) ;border-radius: 8px; box-shadow: 0px 3px 6px #00000029; color:white" class="form-control" value="Search">
            </div>
        </div>
    </div>
</div>
<!--Main Jobs-->

<div class="container">
    
    <div class="row" style="margin-top: 13px;">
        <p>Showing {{p}} - {{count}} of {{totalCount}} results</p>
        <div *ngFor="let job of Jobs | paginate: {itemsPerPage:count, currentPage:p}" class="col-md-6" 
        style="background-color: white; 
         width: 47%; margin-right: 1.5%;margin-left: 1.5%; 
         margin-bottom: 15px;box-shadow: 0px 3px 6px #00000029;border-radius: 8px;padding: 19px;">
            <p style="font-size: 16px; font-family: 'sofia Pro', Bold; color: #354E79;">{{job.jobTitle}}</p>
            <p  class="openAndClose2" style=" font-size: 14px;"><!--*ngIf="!openAndClose"-->
                <img style="width: 18px; height: 18px;" src="../../assets/icons/Blue Employment Type icon.png" alt=""> 
                <span style="padding-right: 24px;color: #333333;font-size: 14px; font-family: sofia Pro, Regular;"> {{job.jobType}}</span>
                <img style="width: 18px; height: 18px;" src="../../assets/icons/Blue Experience icon.png" alt=""> 
                <span style="padding-right: 44px;color: #333333;font-size: 14px; font-family: sofia Pro, Regular;"> {{job.experience}}</span>
                <img style="width: 18px; height: 18px;" src="../../assets/icons/Blue Salary icon.png" alt=""> 
                <span style="padding-right: 44px;color: #333333;font-size: 14px; font-family: sofia Pro, Regular;"> {{job.currencySymbol}}{{job.salary}}</span>
                <img style="width: 18px; height: 18px;" src="../../assets/icons/Blue location icon.png" alt=""> 
                <span style="padding-right: 44px;color: #333333;font-size: 14px; font-family: sofia Pro, Regular;"> {{job.location}}</span>
            </p>
            <p style="height: 90px;"><quill-view-html  [content]="job?.jobDescription"></quill-view-html></p>
            <p style="text-align: right; margin-right: 0%; width: 100%;">
                <a href="viewtheme2/{{job.jobId}}" style="padding-right: 10px; text-decoration: none; ">View Details</a>
                <button class="btn btn" data-toggle="modal" data-target="#exampleModal"  (click)="modal(job.jobId)"
                style="background: linear-gradient(#092047, #354E79) ;border-radius: 8px;width: 140px; 
                color: white;">Apply Now</button>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 text-start">
            <p>Results per page: <select name="" id=""><option value="{{count}}">{{count}}</option></select></p>
        </div>
        <div class="col-md-6 text-end">
            <pagination-controls (pageChange)="p=$event"></pagination-controls>
        </div>
        
    </div>
</div>

<!---Modal-->
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div style="width: 646px; height: auto;" class="modal-content">
        <div class="modal-header">
          <h5 style="margin-left: 37%; color: #354E79;" class="modal-title" id="exampleModalLabel">Application Form</h5>
          <button type="button" class="close btn btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="applyJobForm" (ngSubmit)="applyJob(applyJobForm.value)">
        <div class="modal-body">
          <div class="row">
              <div class="col-md-6">
                  <p style="margin-bottom: 7px; font-size: 12px;">First Name<span style="color: red;">*</span></p>
                  <input formControlName="fname" name="fname" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control">
                  <div *ngIf="isSubmitClicked && applyJobFormControl.fname.errors" class=" alert-danger">
                    <div *ngIf="applyJobFormControl.fname.errors.required" style="color: red;">
                        *First Name is required.
                    </div>
                    <div *ngIf="applyJobFormControl.fname.errors.pattern"  style="color: red;">
                        <p style="font-size: 12px;"><sup>*</sup> Must be only alphabetical order.</p>  
                      </div>
                </div>
                <p style="margin-bottom: 7px; font-size: 12px;">Email Address</p>
                <input formControlName="email" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control"> 
                <div *ngIf="isSubmitClicked && applyJobFormControl.email.errors" class=" alert-danger">
                    
                    <div *ngIf="applyJobFormControl.email.errors.pattern"  style="color: red;">
                        <p style="font-size: 12px;"><sup>*</sup> Please enter valid email address</p>  
                      </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <p style="margin-bottom: 7px; font-size: 12px;">Total Experience</p>
                        <div class="row">
                            <div class="col-md-6">
                                <select formControlName="experienceYear" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                                    <option value="">YY</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <select formControlName="experienceMonth" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control"  name="" id="">
                                    <option value="">MM</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p style="margin-bottom: 7px; font-size: 12px;">Relevant Experience </p>
                        <div class="row">
                            <div class="col-md-6">
                                <select formControlName="relevantYear" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                                    <option value="">YY</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <select formControlName="relevantMonth" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                                    <option value="">MM</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <p style="margin-bottom: 7px; font-size: 12px;">Expected Salary</p>
                <div class="row">
                    <div class="col-md-6">
                        <select style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                            <option value="">currency</option>
                            <option *ngFor="let symbol of currency" value="">{{symbol.symbol}}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <input formControlName="expectedSalary" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control">
                        <div *ngIf="isSubmitClicked && applyJobFormControl.expectedSalary.errors" class=" alert-danger">
                    
                            <div *ngIf="applyJobFormControl.expectedSalary.errors.pattern"  style="color: red;">
                                <p style="font-size: 12px;"><sup>*</sup> Please enter only numbers</p>  
                              </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                  <p style="margin-bottom: 7px;  font-size: 12px;">Last Name</p>
                  <input formControlName="lname" style="margin-bottom: 17px; border-radius: 8px;" type="text" class="form-control">
                  <div *ngIf="isSubmitClicked && applyJobFormControl.lname.errors" class=" alert-danger">
                    
                    <div *ngIf="applyJobFormControl.lname.errors.pattern"  style="color: red;">
                        <p style="font-size: 12px;"><sup>*</sup> Must be only alphabetical order.</p>  
                      </div>
                </div>
                  <p style="margin-bottom: 7px; font-size: 12px;">Contact Number<span style="color: red;">*</span></p>
                  <div class="row">
                      <div class="col-md-3">
                        <select style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id=""><option value="">+1</option></select>
                      </div>
                      <div class="col-md-9">
                        <input style="margin-bottom: 17px; border-radius: 8px;" formControlName="phoneNumber"  type="text" class="form-control">
                        <div *ngIf="isSubmitClicked && applyJobFormControl.phoneNumber.errors" class=" alert-danger">
                            <div *ngIf="applyJobFormControl.phoneNumber.errors.required">
                                *Contact Number is required.
                            </div>
                            <div *ngIf="applyJobFormControl.phoneNumber.errors.pattern"  style="color: red;">
                                <p style="font-size: 12px;"><sup>*</sup> Must be 10 digits number.</p>  
                              </div>
                        </div>
                      </div>
                  </div>
                  <p style="margin-bottom: 7px;  font-size: 12px;">CTC</p>
                  <div class="row">
                    <div class="col-md-4">
                      <select (change)="changeCurrency($event.target.values)" formControlName="currency" style="margin-bottom: 17px; border-radius: 8px;"  class="form-control" name="" id="">
                        <option value="">Curency</option>
                        <option *ngFor="let symbol of currency" value="{{symbol.id}}" values="{{symbol.symbol}}">{{symbol.symbol}}</option>
                    </select>
                    </div>
                    <div class="col-md-7">
                      <input formControlName="currentSalary" style="margin-bottom: 17px; border-radius: 8px;"  type="text" class="form-control">
                      <div *ngIf="isSubmitClicked && applyJobFormControl.currentSalary.errors" class=" alert-danger">
                    
                        <div *ngIf="applyJobFormControl.currentSalary.errors.pattern"  style="color: red;">
                            <p style="font-size: 12px;"><sup>*</sup> Please enter only numbers</p>  
                          </div>
                    </div>
                    </div>
                </div>
              </div>
          </div>
          <!--upload-->
        <div class="row">
            <div class="pad">
                <p style=" font-size: 12px;">Upload Resume</p>
                <div *ngIf="!disappear" style="border: 3px dashed #B2B2B2; padding-top: 30px; padding-bottom: 30px; border-radius: 8px;">
                    <label for="fileInput" style="padding-left: 47%;">
                        <img  [src]="url ? url : '../../assets/icons/Blue Doc icon.png'" alt="">
                        
                    </label>
                    <input type="file" name="resume" id="fileInput"
                    accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     (change)="onSelectFilebase($event)">
                    <!--<img src="../../../../assets/admin/icons/document icon.png" style="padding-left: 47%;" alt="">-->
                    <h6 style="color: black; text-align: center; font-size: 12px;">Click here to upload file</h6>
                    <p style="text-align: center; font-size: 10px;">Format: .pdf, .doc, .docx within ( 2Mb )</p>
                </div>
                <!----->
                <div *ngIf="disappear" style="border: 3px dashed #B2B2B2; padding-top: 30px; padding-bottom: 30px; border-radius: 8px;">
                    <div class="row " 
                    style="width: 40%; margin-left: 30%; background: #efeded; border-radius: 6px; padding-top: 5px;padding-bottom: 5px;">
                    <div class="col-md-4">
                        <img src="../../assets/icons/PDF.png" alt="">
                    </div>
                    <div class="col-md-4" *ngFor="let file of fileList; let i = index">
                        <p>{{file.name}}</p>
                    </div>
                    <div class="col-md-4">
                        <a class="btn btn" (click)="removeDocument()">X</a>
                    </div>
                </div>
                </div>
                <!----->
            </div>
        </div>
        </div>
    
        <div class="text-center" style="padding-bottom: 20px;">
          <!--<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
          <button type="submit" style="background: linear-gradient(#092047, #354E79) ; color: white;"  class="btn btn">Submit</button>
        </div>
    </form>
      </div>
    </div>
  </div>